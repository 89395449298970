import axios from "axios";
import { decode_Token } from "../utils/decodeToken";
import { ErrorMessage, SuccessMessage } from "../utils/message";
import { refreshToken } from "./transferData";
import baseURL from './config.json';


axios.defaults.headers.common["Authorization"] = localStorage.getItem("Access_Token") ? "JWT " + localStorage.getItem("Access_Token"): null;

axios.interceptors.response.use(null,async error => {
    const originalRequest = error.config;
    const exeptedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!exeptedError) {
        ErrorMessage("A problem has occurred on the server side!");
    }
    if (
        error.response.status === 401 &&
        originalRequest.url === baseURL.AriisApi + '/account/token/refresh/'
    ) {
        window.location.href = '/';
        localStorage.removeItem('Refresh_Token');
        localStorage.removeItem('Access_Token');
        SuccessMessage("Please login first!");
        return Promise.reject(error);
    }

    if (
        error.response.status === 401
        // error.response.statusText === 'Unauthorized'
    ) {
        const rfshToken = localStorage.getItem('Refresh_Token');
        if (rfshToken) {
            const decodeToken = decode_Token(rfshToken);
            const date_now = Math.ceil(Date.now() / 1000);
            if (decodeToken.exp > date_now) {
                try {
                    const {data,status} = await refreshToken({"refresh":rfshToken});
                    if (status === 200) {
                        localStorage.setItem('Refresh_Token', data.refresh);
                        localStorage.setItem('Access_Token', data.access);
                        axios.defaults.headers.common['Authorization'] =
                        'JWT ' + data.access;
                        originalRequest.defaults.headers.common['Authorization'] =
                        'JWT ' + data.access;
                        return axios(originalRequest);
                    }
                } catch (error) {
                    console.log(error);
                }
            }else{
                ErrorMessage("Your login has timed out, please login again.")
                window.location.href = "/";
            }
        }else{
            ErrorMessage("Please login first!")
            window.location.href = '/';
        }

    }
    return Promise.reject(error);
})


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default{
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete
}