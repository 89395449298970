import React from 'react';
import { ToastContainer } from 'react-toastify';
import Ariis from './Ariis';

const App = () => {
    return ( 
        <>
            <Ariis/>
            <ToastContainer/>
        </>
    )
}
 
export default App;