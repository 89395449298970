import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {Form,Button} from 'react-bootstrap';
import { replyTicket } from '../../../services/transferData';
import { SuccessMessage } from '../../../utils/message';
import { addTicket } from '../../../reducers/tickets';

const ShowTicket = () => {
    const [ticketMessage,setTicketMessage] = useState("");
    const {id} = useParams();
    const all_ticket = useSelector(state => state.tickets);
    const ticket = all_ticket.filter(x => x.ticket_id === +id)
    const dispatch = useDispatch();

    const handleSubmitTicket = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('ticket_id',id);
        formData.append('content',ticketMessage);
        try {
            const {data,status} = await replyTicket(formData);
            if(status === 200) {
                SuccessMessage('Reply sent successfully.');
                setTicketMessage('');
                dispatch(addTicket(data))
            }
        } catch (error) {
                        
        }
    }
    return (
        <>
            <div className='tckt-content-wrapper'>
                <div className='tckt-content-container'>
                    <div className='dashboard-title'>
                        <h6>Ticket</h6>
                        <div className='title-devider'></div>
                    </div>
                    <div className='tckt-content-body'>
                        {ticket[0].message_ticket.map((message,index) => (
                            <div key={index} className={message.typ === 'a' ? 'support-message' : 'user-message'}>
                                <div className='messenger-image'>
                                    <img src={message.typ === 'a' ? '../assets/images/support-avatar1.jpg' : '../assets/images/user-avatar.jpg'} alt='user | support'/>
                                </div>
                                <div className='messenger-content'>
                                    <p>
                                        {message.content}
                                        <span>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(Date.parse(message.tim)))}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {ticket[0].stat === 'o' ? (
                        <div className='p-3'>
                            <div className='row'>
                                <Form className='d-flex justify-content-center'>
                                    <Form.Group className='' style={{width:'80%',}}>
                                        <Form.Control as="textarea" placeholder='send message...' name='txt-ticket' value={ticketMessage} rows='2' style={{resize:'none'}} onChange={(e) => setTicketMessage(e.target.value)}/>
                                    </Form.Group>
                                    <Form.Group className='text-center align-self-center' style={{width:'20%',}}>
                                        <Button type='submit' className='w-75' onClick={handleSubmitTicket}>Send</Button>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>  
                    ) : (
                        <div className='w-100 p-4 text-center'>
                            <p className='text-danger h5'>This ticket is closed.</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
 
export default ShowTicket;