import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import UserContext from '../context/UserContext';
import UserTableContext from '../context/UserTableContext';
import {MainLayout,Dashboard,Profile,ChangePassword,Addmenu,AddModel,AddTicket,TextureList,Tickets,ShowTicket,Feature,Login,Logout,Register,NotFound, Reports} from '../components/index';

const Ariis = () => {
    return ( 
        <Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<NotFound/>}/>
                    <Route path='/logout' element={<Logout/>}/>
                    <Route path='/register' element={<UserContext><Register/></UserContext>}/>
                    <Route path='/' index element={<UserContext><Login/></UserContext>}/>
                    <Route path='/dashboard' element={<MainLayout/>}>
                        <Route path='report' element={<Reports/>}/>
                        <Route path='add-ticket' element={<AddTicket/>}/>
                        <Route path='feature' element={<Feature/>}/>
                        <Route path='ticket' element={<Tickets/>}/>
                        <Route path='ticket/:id' element={<ShowTicket/>}/>
                        <Route path='addtexture' element={<AddModel/>}/>
                        <Route path='addmenu' element={<UserTableContext><Addmenu/></UserTableContext>}/>
                        <Route path='textures' element={<UserTableContext><TextureList/></UserTableContext>}/>
                        <Route path='changepass' element={<ChangePassword/>}/>
                        <Route path='profile' element={<Profile/>}/>
                        <Route index element={<Dashboard/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Fragment>
    );
}
 
export default Ariis;