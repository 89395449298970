import React, { Fragment, useRef, useState } from "react";

import { Form, FormSelect, Button, Overlay } from "react-bootstrap";

import Multiselect from "multiselect-react-dropdown";

import { isEmpty, max, over } from "lodash";

import AddTextureModal from "../../modals/AddTextureModal";

import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from "../../../utils/message";

import { Helmet } from "react-helmet";

import { add_model, checkProCode } from "../../../services/transferData";

import SimpleReactValidator from "simple-react-validator";

import { useDispatch, useSelector } from "react-redux";

import { compressImage } from "../../../utils/compress";

import { addModel } from "../../../reducers/models";

const AddModel = () => {
  // TODO: All state

  const [showModal, setShowModal] = useState(false);

  const [listFace, setListFace] = useState([]);

  const [key, setKey] = useState("");

  const [value, setValue] = useState("");

  const [, setForceUpdate] = useState();

  const [checkPcode, setCheckPcode] = useState(false);

  const [dataModel, setDataModel] = useState({
    name: "",

    procode: "",

    act: "a",

    menu: "",

    featur_value: {},
  });

  const menus = useSelector((state) => state.menus);

  const filter = useSelector((state) => state.features);

  const dispatch = useDispatch();

  // TODO: validator roll for input's validation

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Please fill out this field!",

        max: "More than 100 characters are not allowed!",
      },

      element: (message) => (
        <div
          style={{
            color: "red",
            fontSize: "small",
            paddingTop: "5px",
            textAlign: "left",
          }}
        >
          {message}
        </div>
      ),
    })
  );

  // TODO: add input's value to owner state

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setDataModel({ ...dataModel, [name]: value });

    validator.current.showMessageFor(name);

    if (value === "") {
      setCheckPcode(false);
    }
  };

  // TODO: add data to feature state with check

  const handleCheck = (event, key) => {
    const { value } = event.target;

    if (key.toString() === "سایز" || key.toString() === "size") {
      setKey(key);

      setValue(value);

      handleShowModal();

      if (
        isEmpty(dataModel.featur_value) ||
        dataModel.featur_value[key] === undefined
      ) {
        dataModel.featur_value[key] = {};

        dataModel.featur_value[key][value] = [];
      } else {
        dataModel.featur_value[key][value] = [];
      }
    } else {
      if (
        isEmpty(dataModel.featur_value) ||
        dataModel.featur_value[key] === undefined
      ) {
        dataModel.featur_value[key] = new Array(value);
      } else {
        dataModel.featur_value[key].push(value);
      }
    }

    setDataModel({ ...dataModel, featur_value: dataModel.featur_value });
  };

  // TODO: remove data from feature state with uncheck

  const handleUnCheck = (event, key) => {
    const { value } = event.target;

    if (key.toString() === "سایز" || key.toString() === "size") {
      delete dataModel.featur_value[key][value];

      if (Object.values(dataModel.featur_value[key]).length === 0)
        delete dataModel.featur_value[key];
    } else {
      const index = dataModel.featur_value[key].indexOf(value);

      if (index !== -1) {
        dataModel.featur_value[key].splice(index, 1);

        if (Object.values(dataModel.featur_value[key]).length === 0)
          delete dataModel.featur_value[key];
      }
    }

    setDataModel({ ...dataModel, featur_value: dataModel.featur_value });
  };

  // TODO: submit add product form

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", dataModel.name);

    formData.append("procode", dataModel.procode);

    formData.append("act", dataModel.act);

    formData.append("menu", dataModel.menu);

    formData.append("featur_value", JSON.stringify(dataModel.featur_value));

    if (validator.current.allValid() && checkPcode === false) {
      if ("size" in dataModel.featur_value) {
        try {
          const { data, status } = await add_model(formData);

          if (status === 200) {
            SuccessMessage("Product added successfully!");

            handelResetState();

            dispatch(addModel(data));
          }
        } catch (error) {
          if (error.response.status === 402) {
            WarningMessage(error.response.data);
          } else {
            ErrorMessage("The texture was not added successfully!");
          }
        }
      } else {
        WarningMessage("Please add size property!");
      }
    } else {
      validator.current.showMessages();

      setForceUpdate(1);
    }
  };

  // TODO: reset all state and uncheck all checkbox after add texture

  const handelResetState = () => {
    setDataModel({
      name: "",

      procode: "",

      act: "active",

      menu: "",

      featur_value: {},
    });

    let x = document.getElementsByClassName("form-check-input");

    for (let i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  };

  // TODO:

  const handleAddPic = (event) => {
    const files = [...event.target.files];

    if (files && files.length <= 10) {
      // const x = compressImage(event)

      setListFace(files);
    } else {
      WarningMessage("The number of photos must be less than 10!");
    }
  };

  // TODO: check procode in the server

  const handleCheckProcode = async (procode) => {
    if (procode !== "") {
      const formData = new FormData();

      formData.append("procode", procode);

      try {
        const { status } = await checkProCode(formData);

        if (status === 200) {
          SuccessMessage("Product ID is unique!");

          setCheckPcode(false);
        }
      } catch (error) {
        setCheckPcode(true);
      }
    }
  };

  // TODO: confirm or reject modal add face texture

  const handleConfaceList = () => {
    const arrayBase64 = [];

    listFace.forEach((ele) => {
      compressImage(ele).then((res) => {
        arrayBase64.push(res);
      });
    });

    dataModel.featur_value[key][value] = arrayBase64;

    setDataModel({ ...dataModel, featur_value: dataModel.featur_value });

    setListFace([]);
  };

  const handleRejFaceList = () => {
    setListFace([]);

    delete dataModel.featur_value[key][value];

    if (Object.values(dataModel.featur_value[key]).length === 0)
      delete dataModel.featur_value[key];

    setDataModel({ ...dataModel, featur_value: dataModel.featur_value });

    let x = document.getElementsByClassName("form-check-input");

    for (let i = 0; i < x.length; i++) {
      if (x[i].value === value) x[i].checked = false;
    }
  };

  // TODO: delete every img in faceList per click and set new list in faceList

  const handleDeletSingleFace = (id) => {
    const filterFaceList = listFace.filter((x) => x.lastModified !== id);

    setListFace(filterFaceList);
  };

  // TODO: handle changes in the new dropdowns

  const handleDropdownChange = (e, key) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    // Update the featur_value state with the selected options for the specific key

    setDataModel((prevState) => ({
      ...prevState,

      featur_value: {
        ...prevState.featur_value,

        [key]: selectedOptions,
      },
    }));
  };

  // TODO: show and close modal

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Dashboard | Add Product</title>
      </Helmet>

      <div className="add-model-wrapper">
        <div className="add-model-container">
          <div className="dashboard-title">
            <h6>Add Product</h6>

            <div className="title-devider"></div>
          </div>

          <div className="add-model-body">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-input">
                    <label htmlFor="name">Product Name:</label>

                    <sub>*</sub>

                    <Form.Control
                      type="text"
                      placeholder="product name..."
                      id="name"
                      name="name"
                      value={dataModel.name}
                      onChange={(e) => handleInputChange(e)}
                    />

                    {validator.current.message(
                      "name",
                      dataModel.name,
                      "required"
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-input">
                    <label htmlFor="procode">Product ID:</label>

                    <sub>*</sub>

                    <Form.Control
                      type="text"
                      placeholder="product id..."
                      id="procode"
                      name="procode"
                      value={dataModel.procode}
                      onBlur={(e) => handleCheckProcode(e.target.value)}
                      onChange={(e) => handleInputChange(e)}
                    />

                    {checkPcode ? (
                      <span
                        style={{
                          color: "red",
                          fontSize: "small",
                          paddingTop: "5px",
                          textAlign: "right",
                        }}
                      >
                        کد محصول از قبل وجود دارد!
                      </span>
                    ) : null}

                    {validator.current.message(
                      "procode",
                      dataModel.procode,
                      `required|max:100`
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-input">
                    <label htmlFor="act">Product Status:</label>

                    {/* <sub>*</sub> */}

                    <FormSelect
                      id="act"
                      name="act"
                      value={dataModel.act}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="a">Active</option>

                      <option value="n">Inactive</option>
                    </FormSelect>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-input">
                    {/* <sub></sub> */}

                    <label>Category:</label>

                    <Multiselect
                      options={menus}
                      showCheckbox
                      onSelect={(e) =>
                        setDataModel({ ...dataModel, menu: e[0].object_id })
                      }
                      onRemove={(e) => setDataModel({ ...dataModel, menu: "" })}
                      placeholder="category..."
                      customCloseIcon={<i className="fa fa-close"></i>}
                      customArrow={<i className="fa fa-angle-down"></i>}
                      hidePlaceholder
                      showArrow
                      avoidHighlightFirstOption
                      selectionLimit={1}
                      closeOnSelect={true}
                      displayValue="name"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
               <div className="row">
                    {!isEmpty(filter)
                    ? Object.entries(filter).map((x, index) => (
                      <div className="col-sm-12 col-md-6 col-lg-3">

                        <div key={index}>
                        <div className="">
                          <h6 className="model-feild-title ">{x[0]}</h6>

                          <div className="model-feild-content col-sm-12 col-md-12">
                            <Form.Group
                              className="mb-1 border"
                              controlId={`formBasicSelect-${index}`}
                            >
                              <Form
                              className="overflow-auto p-3 bg-light"
                                multiple="multiple"
                                value={dataModel.featur_value[x[0]] || []} // Use the selected values from state for each dropdown
                                onChange={(e) => handleDropdownChange(e, x[0])} // Call the new handler for dropdown changes
                              style={{maxHeight:"115px",minHeight:"115px"}}
                              >

{/* <div class="overflow-auto p-3 bg-light"
  style="max-width: 260px; max-height: 100px;"> */}


                                {!isEmpty(x[1])
                                  ? x[1].map((y, index) => (
                                      <Form.Check
                                        key={index}
                                        type="checkbox"
                                        label={y}
                                        value={y}
                                        onChange={(e) =>
                                          e.currentTarget.checked
                                            ? handleCheck(e, x[0])
                                            : handleUnCheck(e, x[0])
                                        }
                                      />
                                    ))
                                  : ""}
                            {/* </div> */}


                              </Form>
                            </Form.Group>
                          </div>
                          </div>
                        </div>
                        </div>
                      ))
                    : ""}
                  </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="devider"></div>

                  <div className="form-input text-end py-3">
                    <Button
                      type="submit"
                      className="w-25"
                      variant="none"
                      onClick={handleAddProduct}
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <AddTextureModal
          handleShowModal={showModal}
          handleCloseModal={handleCloseModal}
          listFace={listFace}
          handleAddPic={handleAddPic}
          ConFaceList={handleConfaceList}
          RejFaceList={handleRejFaceList}
          deleteFace={handleDeletSingleFace}
        />
      </div>
    </Fragment>
  );
};

export default AddModel;
