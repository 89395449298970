import React from 'react'
import { Helmet } from 'react-helmet';
import FeatureTable from '../../tables/FeatureTable';

const Feature = () => {
    return ( 
        <>  
            <Helmet>
                <title>Dashboard | Filters</title>
            </Helmet>
            <div className='feature-wrapper'>
                <div className='feature-container'>
                    <div className='dashboard-title'>
                        <h6>Filters</h6>
                        <div className='title-devider'></div>
                    </div>
                    <div className='feature-body'>
                        <FeatureTable/>
                    </div>
                </div>

            </div>
        </>
     );
}
 
export default Feature;