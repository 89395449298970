import { range } from 'lodash';
import React from 'react';
import { Pagination } from 'react-bootstrap';

const Paginate = ({totalState,currentPage,perPage,pageChange}) => {
    const pageCount = Math.ceil(totalState / perPage);
    if (pageCount === 0) {
        return null;
    }
    const pagePaginate = range(1,pageCount + 1);

    return ( 
        <div className='paginate-container '>
            <Pagination>
                <Pagination.First onClick={e => {
                    if(currentPage > 1) pageChange(currentPage - 1);
                }}/>
                    {pagePaginate.map(x => (
                        <Pagination.Item key={x} active={currentPage === x ? "active" : ""} onClick={e => pageChange(x)}>{x}</Pagination.Item>
                    ))}
                <Pagination.Last onClick={e => {
                    if( currentPage < pagePaginate.length) pageChange(currentPage + 1);
                }}/>
            </Pagination>
        </div>
     );
}
 
export default Paginate;