import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';

const Package = ({formData,setFormData}) => {
    const [selItem, setSelItem] = useState(null);
    const handleChange = (item) => {
        item === selItem ? setSelItem(null) : setSelItem(item);
    };

    const handleDisableSelect = (item) => {
        let select = document.querySelectorAll('select');
        setFormData({...formData,package_price: "asdasdas"});
        select.forEach(x => {
            if(x.className === item){
                x.removeAttribute('disabled');
                console.log(x.options[x.selectedIndex].value);
                // setFormData({...formData,package_price: x.options[x.selectedIndex].value})
            }else{
                x.setAttribute('disabled','disabled')
            }
        })
    }

    return ( 
        <fieldset>
            <Card className='shadow'>
                <div className='information_wrapper form_field'>
                    <div className='panel-row'>
                        <div className='panel'>
                            <div className='panel_title'>
                                <h4>سرویس برنزی</h4>
                            </div>
                            <div className='panel_delails'>
                                <p>
                                <i className='fa fa-check pe-1'></i>امکان مدیریت پنل شخصی</p>
                                <p><i className='fa fa-check pe-1'></i>10 عددمحصول همزمان</p>
                                <p><i className='fa fa-check pe-1'></i>300 عدد عکس آپلود شده</p>
                            </div>
                            <div className='form-input panel_prices'>
                                <select className='p1'>
                                    <option selected>یک ماهه-1 میلیون</option>
                                    <option>شش ماهه-3 میلیون</option>
                                    <option>یکساله-6 میلیون</option>
                                </select>
                            </div>
                            <Form.Check type="checkbox" label="انتخاب سرویس" value='p1' checked={selItem === "p1"} onChange={(e) => {
                                handleChange('p1');
                                handleDisableSelect(e.target.value)
                                setFormData({...formData,package:e.target.value})
                            }}/>
                        </div>
                        <div className='panel'>
                            <div className='panel_title'>
                                <h4>سرویس نقره‌ای</h4>
                            </div>
                            <div className='panel_delails'>
                                <p><i className='fa fa-check pe-1'></i>امکان مدیریت پنل شخصی</p>
                                <p><i className='fa fa-check pe-1'></i>10 عددمحصول همزمان</p>
                                <p><i className='fa fa-check pe-1'></i>300 عدد عکس آپلود شده</p>
                            </div>
                            <div className='form-input panel_prices'>
                                <select className='p2'>
                                    <option selected>یک ماهه-2 میلیون</option>
                                    <option>شش ماهه-5 میلیون</option>
                                    <option>یکساله-8 میلیون</option>
                                </select>
                            </div>
                            <Form.Check type="checkbox" label="انتخاب سرویس" value='p2' checked={selItem === "p2"} onChange={(e) => {
                                handleChange('p2');
                                handleDisableSelect('p2');
                                setFormData({...formData,package:e.target.value});
                            }}/>
                        </div>
                        <div className='panel'>
                            <div className='panel_title'>
                                <h4>سرویس طلایی</h4>
                            </div>
                            <div className='panel_delails'>
                                <p><i className='fa fa-check pe-1'></i>امکان مدیریت پنل شخصی</p>
                                <p><i className='fa fa-check pe-1'></i>10 عددمحصول همزمان</p>
                                <p><i className='fa fa-check pe-1'></i>300 عدد عکس آپلود شده</p>
                            </div>
                            <div className='form-input panel_prices'>
                                <select className='p3'>
                                    <option selected>یک ماهه-3 میلیون</option>
                                    <option>شش ماهه-6 میلیون</option>
                                    <option>یکساله-9 میلیون</option>
                                </select>
                            </div>
                            <Form.Check type="checkbox" label="انتخاب سرویس" value="p3" checked={selItem === "p3"} onChange={(e) => {
                                handleChange('p3');
                                handleDisableSelect('p3');
                                setFormData({...formData,package:e.target.value})

                            }}/>
                        </div>
                        <div className='panel'>
                            <div className='panel_title'>
                                <h4>سرویس ویژه</h4>
                            </div>
                            <div className='panel_delails'>
                                <p><i className='fa fa-check pe-1'></i>امکان مدیریت پنل شخصی</p>
                                <p><i className='fa fa-check pe-1'></i>10 عددمحصول همزمان</p>
                                <p><i className='fa fa-check pe-1'></i>300 عدد عکس آپلود شده</p>
                            </div>
                            <div className='form-input panel_prices'>
                                <select className='p4'>
                                    <option selected>یک ماهه-4 میلیون</option>
                                    <option>شش ماهه-7 میلیون</option>
                                    <option>یکساله-10 میلیون</option>
                                </select>
                            </div>
                            {/* <div className='panel_price'>
                                <p className='price_detail'>
                                    <h6>{price.pill_4}</h6>
                                </p>
                            </div> */}
                            <Form.Check type="checkbox" label="انتخاب سرویس" value="p4" checked={selItem === "p4"} onChange={(e) => {
                                handleChange('p4');
                                handleDisableSelect('p4');
                                setFormData({...formData,package:e.target.value});
                            }}/>
                        </div>
                    </div>
                </div>
            </Card>
        </fieldset>
     );
}
 
export default Package;