import React, { useContext, useEffect } from 'react';
import {Form, FormSelect,FormControl,Button} from 'react-bootstrap';
import { tableContext } from '../../context/tableContext';
import Paginate from '../common/Paginate';
import { calcPaginate } from '../../utils/calcPaginate';
import { useSelector } from 'react-redux';

const MenuTable = () => {
    const menus = useSelector(state => state.menus);
    const menu_context = useContext(tableContext);
    const {
        perPage,
        currentPage,
        search,
        ischeckMenu,
        listMenu,
        setListMenu,
        handleChangePerPage,
        handleCurrentPage,
        handleSearch,
        handleDeleteCheckMenu,
        handleActionDeleteCheckMenu,
        handleCheckAllMenu,
        handleCheckSingleMenu
    } = menu_context;

    useEffect(() => setListMenu(menus),[menus]);
    const filtredSearchMenu = listMenu.filter(x => (x.name).toLocaleLowerCase().includes(search));
    const allMenu = calcPaginate(filtredSearchMenu,currentPage,perPage);
    return ( 
        <div className='table-wrapper'>
            <div className='table-container'>
                <div className='table-header'>
                    <div className='header-limit'>
                        <div>
                            <label>Limit:</label>
                            <FormSelect onChange={handleChangePerPage}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option vlaue="50">50</option>
                            </FormSelect>
                        </div>
                    </div>
                    <div className='header-search'>
                        <div>
                            <label>Search:</label>
                            <FormControl
                                type="search"
                                placeholder="search..."
                                className="me-2 border-0 pe-2 ps-2 py-2"
                                aria-label="Search"
                                variant="outline-secondary"
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                    <div className='table-body'>
                        <div className='table-body-action'>
                            <FormSelect onChange={handleDeleteCheckMenu}>
                                <option value=''>Choose Action</option>
                                <option value='delete'>Delete</option>
                            </FormSelect>
                            <Button className='btn-style' onClick={handleActionDeleteCheckMenu}>Action</Button>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th><Form.Check inline  aria-label="option 1" onChange={handleCheckAllMenu}/></th>
                                    <th>Product Name</th>
                                    <th>Icon</th>
                                    <th>Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allMenu.map((x,index) => (
                                    <tr className={index % 2 !== 0 ? "even" : "odd"} key={index}>
                                        <td><Form.Check id={x.object_id} checked={ischeckMenu.includes(x.object_id)} inline  aria-label="option 1" onChange={handleCheckSingleMenu}/></td>
                                        <td>{x.name}</td>
                                        <td>
                                            <img src={x.ticon} alt='menu-icon'/>
                                        </td>
                                        <td>{x.limit}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                    <div className='table-footer'>
                        <Paginate totalState={filtredSearchMenu.length} currentPage={currentPage} perPage={perPage} pageChange={handleCurrentPage}/>
                    </div>
            </div>
        </div>
    );
}
 
export default MenuTable;