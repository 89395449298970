import React from 'react';
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './container/App';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

export const presisstore = persistStore(store);

const root = createRoot(document.getElementById('root'));
root.render(<Provider store={store}><PersistGate loading={null} persistor={presisstore}><App tab="home"/></PersistGate></Provider>)