import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { homeData } from '../services/transferData';

export const fetchHome = createAsyncThunk('homeInfo/fetchHome',async () => {
    const {data} = await homeData();
    return data;
})

const initialState = {};
const homeInfoSlice = createSlice({
    name:"homeInfo",
    initialState,
    extraReducers:builder => {
        builder.addCase(fetchHome.fulfilled,(state,action) => {
            return action.payload;
        })
    }
    
})

export default homeInfoSlice.reducer;

