import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Link } from 'react-router-dom'
import {SubMenu} from '../../index';

const Sidebar = ({handleShowSidebar,showSidebar}) => {

    const navbar = [
        {title:"Dashboard",icon:"../assets/images/home-nav-vector.png",link:"/dashboard",subMenu:[]},
        {title:"Profile",icon:"../assets/images/profile-nav-vector.png",link:"#",subMenu:[
            {name:"Edit Profile",to:"/dashboard/profile"},
            {name:"Change Password",to:"/dashboard/changepass"},
            // {name:"Factors",to:""}
        ]},
        {title:"Setting",icon:"../assets/images/manage-nav-vector.png",link:"#",subMenu:[
            {name:"All products",to:"/dashboard/textures"},
            {name:"Add Menu",to:"/dashboard/addmenu"},
            {name:"Add product",to:"/dashboard/addtexture"},
            {name:"Filters",to:"/dashboard/feature"},
        ]},
        {title:"Tickets",icon:"../assets/images/support-nav-vector.png",link:"/dashboard/ticket",subMenu:[]},
        {title:"Reports",icon:"../assets/images/reports-nav-vector.png",link:"/dashboard/report",subMenu:[]}
    ]
    return ( 
        <div className='sidebar-wrapper'>
            <div className='sidebar-nav'>
            <span className='text-light res-nav-close' onClick={e => handleShowSidebar(null)}>
                <i className={showSidebar ? 'fa fa-angle-left' : 'fa fa-angle-right'}></i>
            </span>
                <ul className='navbar'>
                    <li className='nav-item nav-close' onClick={e => handleShowSidebar(null)}>
                        <a><img src='../assets/images/close-nav-vector.png' alt=''/></a>
                    </li>
                    {navbar.map((x,index) => (
                        <SubMenu
                        key={index}
                        title={x.title} 
                        icon={x.icon}
                        link={x.link}
                        subMenu={x.subMenu}
                        onClick={() => {
                            if(window.innerWidth > 800){if(showSidebar) handleShowSidebar(false)};
                            // if(showSidebar) handleShowSidebar(false);
                        }}
                        />
                    ))}
                    <li className='nav-item nav-logout'>
                        <Link to='/logout'><img src='../assets/images/logout-nav-vector.png' className='ms-2' alt='logout-icon'/><span>LogOut</span></Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
 
Sidebar.propTypes = {
    handleShowSidebar: PropTypes.func,
    showSidebar: PropTypes.bool
}

export default Sidebar;