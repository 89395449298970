import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const ChartBar = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
            text: 'نمودار گزارشات',
          },
        },

      };
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
    labels,
    datasets: [
        {
        label: 'Dataset 1',
        data: labels.map(() => 100),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
        label: 'Dataset 2',
        data: labels.map(() => 50),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
    };
    return ( 
        <Bar data={data} options={options} />
     );
}
 
export default ChartBar;