import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { getTickets } from "../services/transferData";

export const fetchTickets = createAsyncThunk('ticket/fetchTickets', async () => {
    const {data} = await getTickets();
    return data;
});


const initialState = [];

export const ticketSlice = createSlice({
    name:'ticket',
    initialState,
    reducers:{
        addTicket: (state,action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTickets.fulfilled,(state,action) => {
            return action.payload;
        })
    }
});

export default ticketSlice.reducer;
export const {addTicket} = ticketSlice.actions;