import { createContext } from "react";

export const tableContext = createContext({
    // menu table
    menuData:{},
    menuIcon:{},
    setMenuIcon:() => {},
    setMenuData: () => {},
    isCheckAllMenu: false,
    setIsCheckAllMenu: () => {},
    ischeckMenu: [],
    setIsCheckMenu: () => {},
    listMenu:[],
    deleteMenuChecked: false,
    setDeleteMenuCheck: () => {},
    setListMenu: () => {},
    handleDeletMenuIcon: () => {},
    handleSubmitMenu: () => {},
    // common
    perPage: 100,
    setPerPage: () => {},
    currentPage: 1,
    setCurrentPage: () => {},
    handleChangeMenuData: () => {},

    // model table
    deleteModelChecked: false,
    setDeleteModelCheck: () => {},
    isCheckAllModel: false,
    setIsCheckAllModel: () => {},

    ischeckModel: [],
    setIsCheckModel: () => {},

    search: "",
    setSearch: () => {},
    listModel: [],
    setListModel: () => {},

    showEditeModal: false,
    setShowEditeModal: () => {},
    currentModel: {},
    setCurrentModel: () => {},
    handleCurrentPage: () => {},
    handleChangePerPage: () => {},
    handleCheckAllModel: () => {},
    handleCheckSingleModel: () => {},
    handleCheckAllMenu: () => {},
    handleCheckSingleMenu: () => {},
    handleDeleteCheckModel: () => {},
    handleActionDeleteCheckModel: () => {},
    handleSearch: () => {},
    handleSituationModel: () => {},
    handleShowEditeModal: () => {},
    handleCloseEditeModal: () => {},
    handleEditeProduct: () => {}
})