import React from 'react'
import { Helmet } from 'react-helmet';
import TicketTable from '../../tables/TicketTable';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Tickets = () => {
    return ( 
        <>
            <Helmet>
                <title>Dashboard | Tickets</title>
            </Helmet>
            <div className='ticket-wrapper'>

                <div className='ticket-container'>
                    <div className='dashboard-title'>
                        <h6 className='d-inline-flex'>Tickets</h6>
                        <div className='add-ticket d-inline-flex float-start mt-2'>
                            <Link to='/dashboard/add-ticket'><Button variant="light"><i className='fa fa-plus ms-2'></i>Add Ticket</Button></Link>
                        </div>
                        <div className='title-devider'></div>
                    </div>
                    <div className='ticket-body'>
                        <div className='ticket-body-content'>
                            <TicketTable/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Tickets;