import React,{ useEffect } from 'react';
import { presisstore } from '../..';
import { useNavigate } from 'react-router';
import { blockToken } from '../../services/transferData';
import { ErrorMessage, SuccessMessage } from '../../utils/message';

const Logout = () => {
    const redirect = useNavigate();
    useEffect(() => {
        handleBlockToken();
        presisstore.purge();
    });
    const handleBlockToken = async () =>{
    const refreshtoken = localStorage.getItem("Refresh_Token");
    try {
        const {status} = await blockToken({"refresh":refreshtoken});
        if (status === 200) {
            localStorage.removeItem("Access_Token");
            localStorage.removeItem("Refresh_Token");
            redirect('/');
            SuccessMessage("You have successfully logout!")
        }
    } catch (error) {
        ErrorMessage('Unfortunately, something went wrong, please try again!');
    }
}

    return (
        <div className='w-100 position-relative'>
            <div className='w-75 position-relative m-auto'>
                <div className='logout-pic w-100 h-100 m-auto overflow-hidden text-center'>
                    <img src='../assets/images/logout-loading1.gif' width='800px' alt='logoutImage'/>
                </div>
                <div className='text-center'>
                    <b>Please Wait...</b>
                </div>
            </div>
        </div>
    );
}
export default Logout;






