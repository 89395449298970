import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';

const Info = ({formData,setFormData}) => {

    const ref = useRef(null);
    useEffect(() => {
        ref.current.focus();
    },[])
    
    const validator = useRef(new SimpleReactValidator({
        messages:{
            required: "پرکردن این فیلد الزامی می‌باشد!",
            min: "نباید کمتر از 3 کاراکتر باشد!",
            max: "asda",
            in: "با گذرواژه مطابقت ندارد!",
            email:"ایمیل مورد نظر معتبر نمی‌باشد!"
        },
        element: (message) => <div style={{color:'red',fontSize:'small',paddingTop:'5px',textAlign:'right'}}>{message}</div>
    }))
    
    // const country = Country.getAllCountries();
    // console.log(country);
    // const city = State.getStatesOfCountry(formData.country);
    
    return ( 
        <fieldset>
            <Card className="shadow">
                <div className='information_wrapper form_field'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='company_name'>نام شرکت (فروشگاه):</label>
                                <input type='text' ref={ref} placeholder='نام شرکت...' id='company_name' name='companyname' value={formData.compayName} onChange={e => {
                                    setFormData({...formData,compayName: e.target.value});
                                    validator.current.showMessageFor("companyname");
                                    }}/>
                                {validator.current.message("companyname",formData.compayName,"required|min:3")}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='username'>نام کاربری:</label>
                                <input type='text' placeholder='نام کاربری...' id='user_name' name='username' value={formData.userName} onChange={e => {
                                     setFormData({...formData,userName:e.target.value});
                                     validator.current.showMessageFor("username");
                                }}/>
                                {validator.current.message("username",formData.userName,"required|min:3")}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='password'>گذرواژه:</label>
                                <input type='password' placeholder='گذرواژه...' autoComplete='password' id='password' name='password' value={formData.password} onChange={e => {
                                    setFormData({...formData,password:e.target.value});
                                    validator.current.showMessageFor("password");
                                    }}/>
                                {validator.current.message("password",formData.password,"required|min:3")}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='confrim_password'>تایید گذرواژه:</label>
                                <input type='password' placeholder='تایید گذرواژه...' autoComplete='confrimpassword' id='confrim_password' name='confrimpassword' value={formData.confrimPassword} onChange={e => {
                                    setFormData({...formData,confrimPassword:e.target.value});
                                    validator.current.showMessageFor("confrimpassword");
                                }}/>
                                {validator.current.message("confrimpassword",formData.confrimPassword,`required|min:3|in:${formData.password}`)}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='email'>ایمیل:</label>
                                <input type='email' placeholder='ایمیل...' id='email' name='email' value={formData.email} onChange={e => {
                                    setFormData({...formData,email:e.target.value});
                                    validator.current.showMessageFor("email");
                                }}/>
                                {validator.current.message("email",formData.email,"required|email")}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='tel'>تلفن:</label>
                                <input type='text' placeholder='تلفن...' id='tel' name='tel' value={formData.tel} onChange={e => setFormData({...formData,tel:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='country'>کشور:</label>
                                <select name='country' value={formData.country} onChange={e => setFormData({...formData,country:e.target.options[e.target.selectedIndex].value})}>
                                <option value=''>انتخاب کشور</option>
                                    {/* {country.map(x => (
                                        <option value={x.isoCode}>{x.name}</option>
                                    ))} */}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='city'>استان:</label>
                                <select name='city' value={formData.city} onChange={e => setFormData({...formData,city:e.target.options[e.target.selectedIndex].value})}>
                                    <option>انتخاب شهر</option>
                                    {/* {city.map(x => (
                                        <option value={x.isoCode}>{x.name}</option>
                                    ))} */}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='address'>آدرس:</label>
                                <input type='text' placeholder='آدرس...' id='address' name='address' value={formData.address} onChange={e => setFormData({...formData,address:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='company_name'>شرح کاربری شرکت:</label>
                                <textarea rows='3' cols='30' name='companydetail' value={formData.companyDetail} onChange={e => setFormData({...formData,companyDetail:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </fieldset>
     );
}
 
export default Info;