import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { get_menu } from '../services/transferData';

export const fetchMenu = createAsyncThunk("menus/fetchMenu",async () => {
    const {data} = await get_menu();
    return data;
});

const initialState = [];

const menuSlice = createSlice({
    name:"menus",
    initialState,
    reducers:{
        addMenu:(state,action) => {
            return action.payload
        },
        deleteMenu:(state,action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMenu.fulfilled,(state,action) => {
            return action.payload;
        })
    }
});

export default menuSlice.reducer;
export const {addMenu,deleteMenu} = menuSlice.actions;