import React from 'react'
import { Helmet } from 'react-helmet';

const Reports = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard | Reports</title>
      </Helmet> 
      <div className='report-wrapper'>
        <div className='report-container'>
          <div className='dashboard-title'>
            <h6>Reports</h6>
            <div className='title-devider'></div>
          </div>
          <div className='report-body'>
            {window.innerWidth > 992 ? (
              <a href='https://bi.ariisco.com/Reports/powerbi/AriisFolder/RPT2%20-%20new?rs:embed=true' rel='noreferrer' target="_blank" className='btn'>View Reports</a>
            ) : (
              <a href='https://bi.ariisco.com/Reports/powerbi/AriisFolder/RPT2%20-%20new%20-%20mobile?rs:embed=true' rel='noreferrer' target="_blank" className='btn'>View Reports</a>
            )}
          </div>  
        </div>
      </div>
    </>
  )
}

export default Reports;
