import React, { Fragment, useContext, useEffect } from 'react';
import { FormSelect,FormControl,Button,Badge,Form } from 'react-bootstrap';
import { calcPaginate } from '../../utils/calcPaginate';
import Paginate from '../common/Paginate';
import { tableContext } from '../../context/tableContext';
import { useSelector } from 'react-redux';

const Table = () => {
    const models = useSelector(state => state.models)
    const table_context = useContext(tableContext);
    const {
        perPage,
        currentPage,
        listModel,
        setListModel,
        search,
        ischeckModel,
        handleChangePerPage,
        handleSearch,
        handleSituationModel,
        handleDeleteCheckModel,
        handleActionDeleteCheckModel,
        handleCheckAllModel,
        handleShowEditeModal,
        handleCheckSingleModel,
        handleCurrentPage,
        handleEditeProduct
    } = table_context;

    useEffect(() => setListModel(models),[models]);
    const filtredSearchModels = listModel.filter(x => (x.name).toLocaleLowerCase().includes(search.toLocaleLowerCase()) || (x.procode).includes(+search));
    const allModels = calcPaginate(filtredSearchModels,currentPage,perPage);
    return ( 
        <Fragment>
            <div className='table-wrapper'>
                <div className='table-container'>
                    <div className='table-header'>
                        <div className='header-limit'>
                            <div>
                                <label>Rows:</label>
                                <FormSelect onChange={handleChangePerPage}>
                                    <option value="100">100</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                </FormSelect>
                            </div>
                        </div>
                        <div className='header-search'>
                            <div>
                                <label>Search:</label>
                                <FormControl
                                    type="search"
                                    placeholder="search product..."
                                    className="me-2 border-0 pe-2 ps-2 py-2"
                                    aria-label="Search"
                                    variant="outline-secondary"
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div className='header-condition'>
                            <div>
                                <label>Status:</label>
                                <FormSelect onChange={handleSituationModel}>
                                    <option value="">All</option>
                                    <option value="confrimed">Confrim</option>
                                    <option value="notconfrimed">NotConfrim</option>
                                </FormSelect>
                            </div>
                        </div>
                    </div>
                    <div className='table-body-action'>
                        <FormSelect onChange={handleDeleteCheckModel}>
                            <option value="">Choose Action</option>
                            <option value="delete">Delete</option>
                        </FormSelect>
                        <Button className='btn-style' onClick={handleActionDeleteCheckModel}>Apply</Button>
                    </div>
                    <div className='table-body'>
                        <table>
                            <thead>
                                <tr>
                                    <th><Form.Check inline  aria-label="option 1" onChange={handleCheckAllModel}/></th>
                                    <th>Name</th>
                                    <th>Product</th>
                                    <th>Product ID</th>
                                    <th>Request Date</th>
                                    <th>Actions</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allModels.map((x,index) => (
                                    <tr className={(index % 2) === 0 ? "odd": "even"} key={index}>
                                        <td><Form.Check id={x.object_id} checked={ischeckModel.includes(x.object_id)} inline aria-label="option 1" onChange={handleCheckSingleModel}/></td>
                                        <td>{x.name}</td>
                                        <td><img src={x.ticon} alt='texture-icon'/></td>
                                        <td>{x.procode}</td>
                                        <td>{x.date_req ? new Intl.DateTimeFormat('en-US', {year: "numeric", month:"short", day:"2-digit"}).format(new Date(Date.parse(x.date_req))) : ""}</td>
                                        <td>
                                            <Button variant='secondary' onClick={e => {
                                                handleShowEditeModal();
                                                handleEditeProduct(x);
                                            }}>Edit</Button>
                                        </td>
                                        <td>
                                            <Badge bg={x.mode === "c" ? "success" : "danger"}><i className={x.mode === "c" ? "fa fa-check" : " fa fa-close"}></i></Badge>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                    <div className='table-footer'>
                        <Paginate totalState={filtredSearchModels.length} currentPage={currentPage} perPage={perPage} pageChange={handleCurrentPage}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default Table;