import React, { useState } from 'react'
import EditModal from '../components/modals/EditModal';
import { add_menu, delete_menu, delete_model } from '../services/transferData';
import { ErrorMessage, SuccessMessage } from '../utils/message';
import { tableContext } from './tableContext';
import { useDispatch,useSelector } from 'react-redux';
import { addMenu, deleteMenu } from '../reducers/menu';
import { deleteModel } from '../reducers/models';

const UserTableContext = ({children}) => {
    //TODO: state
    // menu table state
    const [menuData,setMenuData] = useState({
        menuname:"",
        menugrade:"",
        menuicon:null
    });
    const [isCheckAllMenu,setIsCheckAllMenu] = useState(false);
    const [ischeckMenu,setIsCheckMenu] = useState([]);
    const [listMenu,setListMenu] = useState([]);
    const [deleteMenuChecked,setDeleteMenuCheck] = useState(false);

    // model table state
    const [isCheckAllModel,setIsCheckAllModel] = useState(false);
    const [ischeckModel,setIsCheckModel] = useState([]);
    const [currentModel,setCurrentModel] = useState({});
    const [listModel,setListModel] = useState([]);
    const [deleteModelChecked,setDeleteModelCheck] = useState(false);
    // common state
    const [perPage,setPerPage] = useState(100);
    const [currentPage,setCurrentPage] = useState(1);
    const [search,setSearch] = useState("");
    const [showEditeModal,setShowEditeModal] = useState(false);
    
    const menus = useSelector(state => state.menus);
    const models = useSelector(state => state.models);
    const dispatch = useDispatch();


    //TODO: change paginate page per click
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    }
    //TODO: change limited model show in table
    const handleChangePerPage = (e) => {
        setPerPage(e.target.value);
    }

    // TODO: cheched all model & single checkbox model
    const handleCheckAllModel = () => {
        setIsCheckAllModel(!isCheckAllModel);
        setIsCheckModel(models.map(x => x.object_id));
        if(isCheckAllModel) {
            setIsCheckModel([]);
        }
    }
    const handleCheckSingleModel = e => {
        const {id,checked} = e.target;
        setIsCheckModel([...ischeckModel,+id]);
        if (!checked) {
            setIsCheckModel(ischeckModel.filter(x => x !== +id));
        }
    }


    // TODO: delete checked models
    const handleDeleteCheckModel = (e) => {
        if (e.target.value === "delete") {
            setDeleteModelCheck(true)
        }else{
            setDeleteModelCheck(false);
        }
    }

    const handleActionDeleteCheckModel = async (e) => {
        e.preventDefault();
        if (deleteModelChecked) {
            try {
                const {data,status} = await delete_model(ischeckModel);
                if(status === 200) {
                    SuccessMessage("The model was successfully deleted!");
                    dispatch(deleteModel(data));
                }
            } catch (error) {}
        }
    }

    //TODO: search on table
    const handleSearch = (e) => {
        if (e.target.value !== "") {
            setSearch(e.target.value);
        }else{
            setSearch("");
        }
    }

    // TODO: set situation's models
    const handleSituationModel = (e) => {
        if (e.target.value === "confrimed") {
            setListModel(models.filter(x => x.mode === "c"));
        }else if (e.target.value === "notconfrimed") {
            setListModel(models.filter(x => x.situation === "n"));
        }else{
            setListModel(models);
        }
    }

    // TODO:
    const handleEditeProduct = (model) => {
        setCurrentModel(model);
    }
    // ----------------------------------------------------------------
    // TODO: delete checked models
    const handleDeleteCheckMenu = (e) => {
        if (e.target.value === "delete") {
            setDeleteMenuCheck(true)
        }else{
            setDeleteMenuCheck(false);
        }
    }
    
    const handleActionDeleteCheckMenu = async (e) => {
        e.preventDefault();
        if (deleteMenuChecked) {
            try {
                const {data,status} = await delete_menu(ischeckMenu);
                if (status === 200) {
                    SuccessMessage("The desired menu has been successfully deleted!");
                    dispatch(deleteMenu(data));
                }
            } catch (error) {
                ErrorMessage('Unfortunately, something went wrong, please try again!');
            }
        }
    }

    // TODO: set data add menu state
    const handleChangeMenuData = (event) => {
        const {value,name} = event.target;
        if (name === "menuicon") {
            setMenuData({...menuData,[name]: event.target.files[0]});
        }else{
            setMenuData({...menuData,[name]: value});
        }
    }
 
    // TODO: delete menu's icon
    const handleDeletMenuIcon = (e) => {
        setMenuData({...menuData,menuicon: null});
    }
    //TODO: add menu
    const handleSubmitMenu = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("menuname",menuData.menuname);
        formData.append("menugrade",menuData.menugrade);
        formData.append("menuicon",menuData.menuicon);
        try {
            const {data,status} = await add_menu(formData);
            if (status === 200) {
                SuccessMessage("The menu has been successfully added!");
                dispatch(addMenu(data));
                handleResetMenuState();
            }
        } catch (error) {
            ErrorMessage("Failed to add menu!");
        }
    }
    // TODO: reset menu state after submit
    const handleResetMenuState = () => {
        setMenuData({
            menuname:"",
            menugrade:"",
            menuicon:null
        })
    }
    // TODO: cheched all menu & single checkbox menu
    const handleCheckAllMenu = () => {
        setIsCheckAllMenu(!isCheckAllMenu);
        setIsCheckMenu(menus.map(x => x.object_id));
        if(isCheckAllMenu) {
            setIsCheckMenu([]);
        }
    }
    const handleCheckSingleMenu = e => {
        const {id,checked} = e.target;
        setIsCheckMenu([...ischeckMenu,+id]);
        if (!checked) {
            setIsCheckMenu(ischeckMenu.filter(x => x !== +id));
        }
    }



    // TODO: open and close edite modal
    const handleShowEditeModal = () => setShowEditeModal(true);
    const handleCloseEditeModal = () => setShowEditeModal(false);

    return ( 
        <tableContext.Provider value={{
            menuData,
            setMenuData,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
            deleteModelChecked,
            setDeleteModelCheck,
            isCheckAllModel,
            setIsCheckAllModel,
            ischeckModel,
            setIsCheckModel,
            isCheckAllMenu,
            setIsCheckAllMenu,
            ischeckMenu,
            setIsCheckMenu,
            deleteMenuChecked,
            setDeleteMenuCheck,
            search,
            setSearch,
            listModel,
            setListModel,
            listMenu,
            setListMenu,
            showEditeModal,
            setShowEditeModal,
            handleCurrentPage,
            handleChangePerPage,
            handleCheckAllModel,
            handleCheckSingleModel,
            handleCheckAllMenu,
            handleCheckSingleMenu,
            handleDeleteCheckModel,
            handleActionDeleteCheckModel,
            handleSearch,
            handleSituationModel,
            handleShowEditeModal,
            handleCloseEditeModal,
            handleEditeProduct,
            handleChangeMenuData,
            handleDeletMenuIcon,
            handleSubmitMenu,
            handleDeleteCheckMenu,
            handleActionDeleteCheckMenu

            
        }}>
            <EditModal showEditeModal={showEditeModal} closeEditeModal={handleCloseEditeModal} currentModel={currentModel}/>
            {children}
        </tableContext.Provider>
     );
}
 
export default UserTableContext;