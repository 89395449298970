import React, { useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';

const Profile = () => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current.focus();
    },[])
    return ( 
        <div className="profile-wrapper">
            <div className='profile-container shadow'>
                <div className='dashboard-title'>
                    <h6>Profile Setting</h6>
                    <div className='title-devider'></div>
                </div>
                <div className='profile-body'>
                    <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <label htmlFor='company_name'>Company Name:</label>
                                <sub>*</sub>
                                <input type='text' ref={ref} placeholder='company name...' id='company_name' name='companyname'/>
                                {/* {validator.current.message("companyname",formData.compayName,"required|min:3")} */}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <label htmlFor='username'>User Name:</label>
                                <sub>*</sub>
                                <input type='text' placeholder='user name...' id='user_name' name='username'/>
                                {/* {validator.current.message("username",formData.userName,"required|min:3")} */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <label htmlFor='email'>Email:</label>
                                <sub>*</sub>
                                <input type='email' placeholder='email...' id='email' name='email'/>
                                {/* {validator.current.message("email",formData.email,"required|email")} */}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <label htmlFor='tel'>Tel.:</label>
                                <sub>*</sub>
                                <input type='text' placeholder='tel...' id='tel' name='tel'/>
                            </div>
                        </div>
                    </div>
                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='country'>کشور:</label>
                                <select name='country'>
                                <option value=''>انتخاب کشور</option>

                                </select>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input'>
                                <sub>*</sub>
                                <label htmlFor='city'>استان:</label>
                                <select name='city'>
                                    <option>انتخاب شهر</option>

                                </select>
                            </div>
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-input'>
                                <label htmlFor='address'>Address:</label>
                                <sub>*</sub>
                                <input type='text' placeholder='address...' id='address' name='address'/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='devider'></div>
                            <div className='form-input text-end py-3'>
                                <Button type='submit' variant='none'>Save Changes</Button>
                            </div>
                        </div>
                    </div>

                    </Form>
                </div>
            </div>
        </div>
     );
}
 
export default Profile;