import { toast } from "react-toastify"

export const SuccessMessage = (message) => {
    toast.success(message,{
        position:"bottom-left",
        autoClose:1500,
        closeButton:true,
        closeOnClick:true
    })
}

export const ErrorMessage = (message) => {
    toast.error(message,{
        position:"bottom-left",
        autoClose:1500,
        closeButton:true,
        closeOnClick:true
    })
}
export const WarningMessage = (message) => {
    toast.warning(message,{
        position:"bottom-left",
        autoClose:1500,
        closeButton:true,
        closeOnClick:true
    })
}