import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Table from '../../tables/Table';

const TextureList = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Dashboard | Product</title>
            </Helmet>
            <div className='texture-list-wrapper'>
                <div className='texture-list-container shadow'>
                    <div className='dashboard-title'>
                        <h6>Product List</h6>
                        <p className='title-devider'></p>
                    </div>
                    <div className='texture-list-body'>
                        <Table/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default TextureList;