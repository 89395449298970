import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const Factor = () => {
    return ( 
        <fieldset>
            <Card className='shadow'>
                <div className='information_wrapper form_field'>
                    <div className='invoce-container text-center'>
                        <div className='invoice_header'>
                            <div className='invoice_header_logo w-50 d-inline-block'>
                                <img src='../assets/images/logo-ariis.png' alt='logo-ariis'/>
                            </div>
                            <div className='invoice_header_detail text-dark w-50 d-inline-block'>
                                <p>اصفهان،خیابان میر،کوی سررشته داری،شرکت واقعیت افزوده اسپادانا</p>
                                <p>تلفن:0313123456</p>
                            </div>
                        </div>
                        <div className="invoice_title">
                            <h3>فاکتور فروش</h3>
                        </div>
                        <div className='invoice_body'>
                            <div className='invoce-information'>
                                <div className='invoce-information-title d-flex'>
                                    <p className='w-50'>تاریخ فاکتور:</p>
                                    <p className='w-50'>اطلاعات مشتری:</p>
                                </div>
                                <div className='invoce-information-detail d-flex'>
                                    <p className='w-50'>سه شنبه 3 خرداد 1401</p>
                                    <p className='w-50'>
                                        <ul>
                                            <li>نام خریدار:</li>
                                            <li>شماره تلفن خریدار:</li>
                                            <li>شماره فاکتور:</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className='w-100 invoice-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ردیف</th>
                                            <th>نام سرویس</th>
                                            <th>تعداد ماه</th>
                                            <th>مبلغ</th>
                                            <th>توضیحات</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>نقره‌ای</td>
                                            <td>3 ماهه</td>
                                            <td>3 میلیون تومان</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='sum-invoice'>
                                <div className='sum-invoice-detail'>
                                    <table id='sum-invoice-table'>
                                        <tbody>
                                            <tr>
                                                <td>مالیات</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>بستانکاری</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>بدهکاری</td>
                                                <td>0</td>
                                            </tr>
                                            <tr className='sum-pills'>
                                                <td>جمع کل</td>
                                                <td>3 میلیون</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='sum-invoice-conditions'>
                                    <div className='sum-invoice-conditions-title'>
                                        <h5>جرئیات خرید:</h5>
                                    </div>
                                    <ul>
                                        <li>
                                            <Form.Check type='radio' aria-label="radio 1" label='پرداخت به صورت اقساطی'/>
                                        </li>
                                        <li>
                                            <Form.Check type='radio' aria-label="radio 1" label='پرداخت به صورت'/>
                                        </li>
                                        <li>
                                            <Form.Check type='radio' aria-label="radio 1" label='dkjsandjkas'/>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Card>
        </fieldset>
     );
}
 
export default Factor;