import React, { Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ChangePassword = () => {
    return ( 
        <Fragment>
            <Helmet>
                <title>Dashboard | Change Password</title>
            </Helmet>
            <div className='chng-pass-wrapper'>
                <div className='chng-pass-container shadow'>
                    <div className='dashboard-title'>
                        <h6>Change Password</h6>
                        <div className='title-devider'></div>
                    </div>
                    <div className='chng-pass-body'>
                    <Form></Form>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-input'>
                                    <label htmlFor='email'>Current Password:</label>
                                    <sub>*</sub>
                                    <input type='text' placeholder='current password...' id='email' name='email'/>
                                    {/* {validator.current.message("email",formData.email,"required|email")} */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-input'>
                                    <label htmlFor='email'>New Password:</label>
                                    <sub>*</sub>
                                    <input type='text' placeholder='new password...' id='email' name='email'/>
                                    {/* {validator.current.message("email",formData.email,"required|email")} */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-input'>
                                    <label htmlFor='email'>Confrim Password:</label>
                                    <sub>*</sub>
                                    <input type='text' placeholder='confrim password...' id='email' name='email'/>
                                    {/* {validator.current.message("email",formData.email,"required|email")} */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='devider'></div>
                                <div className='form-input text-end py-3'>
                                    <Button type='submit' variant='none'>Save Changes</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default ChangePassword;