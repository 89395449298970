import React, { useContext } from 'react';
import { Form, Button,FormSelect } from 'react-bootstrap';
import { tableContext } from '../../../context/tableContext';
import MenuTable from '../../tables/MenuTable';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Addmenu = () => {
    const menus = useSelector(state => state.menus);
    const addmenu_contetx = useContext(tableContext);
    const {
        menuData,
        handleChangeMenuData,
        handleDeletMenuIcon,
        handleSubmitMenu,
    } = addmenu_contetx;

    return (
        <>
            <Helmet>
                <title>Dashboard | Add Menu</title>
            </Helmet>
            <div className='addmenu-wrapper'>
                <div className='addmenu-container shadow'>
                    <div className='dashboard-title'>
                        <h6>Add Menu</h6>
                        <div className='title-devider'></div>
                    </div>
                    <div className='addmenu-body'>
                        <div className='addmenu-body-form'>
                            <Form encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-input'>
                                            <label htmlFor='menuname'>Name:</label>
                                            <sub>*</sub>
                                            <input type='text' placeholder='name...' id='menuname' name='menuname' value={menuData.menuname} onChange={handleChangeMenuData}/>
                                        </div>
                                        <small className='blockquote-footer'>The name that is shown on your website.</small>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-input'>
                                            <label htmlFor='menugrade'>Category:</label>
                                            <sub>*</sub>
                                            <FormSelect id='menugrade' name='menugrade' value={menuData.menugrade} onChange={handleChangeMenuData}>
                                                <option value=''>None</option>
                                                {menus.map((ele,index) => (
                                                    <option key={index} value={ele.object_id}>{ele.name}</option>
                                                ))}
                                            </FormSelect>
                                        </div>
                                        <small className='blockquote-footer'>Main categories can have sub-categories.</small>
                                    </div>
                                </div>
                                <div className='row align-items-center mt-5'>
                                    <div className='col-6 col-md-6'>
                                        <div className='form-input'>
                                            <label htmlFor='menuicon' className='btn btn-style text-light'>Add Icon</label>
                                            <input type='file' className='d-none' accept="image/*" id='menuicon' name='menuicon' onChange={handleChangeMenuData}/>
                                        </div>
                                    </div>
                                    <div className='col-6 col-md-6'>
                                        <div className='form-input text-center position-relative'>
                                            <img src={menuData.menuicon !== null ? URL.createObjectURL(menuData.menuicon) : "../assets/images/default2.png" } width="75" height="75" alt='icon'/>
                                            <small className={menuData.menuicon === null ? "invisible" : "px-1 visible"} role="button" onClick={handleDeletMenuIcon}> <i className="fa fa-trash text-danger"></i></small>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-12'>
                                        <div className='devider'></div>
                                        <div className='form-input text-end'>
                                            <Button type='submit' className='w-50' variant='none' onClick={handleSubmitMenu}>Add Menu</Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className='addmenu-body-table'>
                            <MenuTable/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Addmenu;