import React from 'react'
import { isEmpty } from 'lodash';
import { Button,Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TicketTable = () => {
    const all_ticket = useSelector(state => state.tickets);
    return ( 
        <>
            <div className='table-wrapper'>
                <div className='table-container'>
                    <div className='table-body'>
                        <table>
                            <thead>
                                <tr>
                                    <th>R</th>
                                    <th>Title</th>
                                    <th>Update Date</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(all_ticket) ? all_ticket.map((ele,index) => (
                                    <tr key={index} className={(index % 2) === 0 ? "odd": "even"}>
                                        <td>{index + 1}</td>
                                        <td>{ele.title}<Badge bg={ele.stat === "o" ? 'success' : 'danger'} className='inline-block me-2' style={{width:'max-content'}}>{ele.stat === "o" ? "open" : "close"}</Badge></td>
                                        <td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(Date.parse(ele.tim)))}</td>
                                        <td>
                                            <Link to={`/dashboard/ticket/${ele.ticket_id}`}>
                                                <Button variant='light'><i className='fa fa-eye ms-2'></i>View</Button>
                                            </Link>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default TicketTable;