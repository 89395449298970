import { configureStore,combineReducers } from "@reduxjs/toolkit";
import modelSlice from '../reducers/models';
import homeInfoSlice from '../reducers/homeInfo';
import menuSlice from '../reducers/menu';
import featureSlice from '../reducers/features';
import ticketSlice from "../reducers/tickets";
import storage from "redux-persist/lib/storage";
import {persistReducer, REHYDRATE, PERSIST, PURGE} from 'redux-persist';

const persistConfig = {
    key: 'root',
    version: 1,
    storage
};

const reducer = combineReducers({
    models: modelSlice,
    homeInfo: homeInfoSlice,
    menus: menuSlice,
    features:featureSlice,
    tickets:ticketSlice
})

const persistedReducer = persistReducer(persistConfig,reducer);

export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REHYDRATE, PERSIST, PURGE],
      },
    }),
})