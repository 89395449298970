import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import {Badge} from 'react-bootstrap'
import { isEmpty } from 'lodash';
const FeatureTable = () => {
    const all_feature  = useSelector(state => state.features);
    return ( 
        <Fragment>
            <div className='table-wrapper'>
                <div className='table-container'>
                    <div className='table-body'>
                        <table>
                            <thead>
                                <tr>
                                    <th>R</th>
                                    <th>Title</th>
                                    <th>Amounts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isEmpty(all_feature) ? "" : Object.entries(all_feature).map((filter,index) => (
                                    <tr key={index} className={(index % 2) === 0 ? "odd": "even"}>
                                        <td>{index + 1}</td>
                                        <td>{filter[0]}</td>
                                        <td>
                                            {!isEmpty(filter[1]) ? filter[1].map((x,index) => (
                                                <Badge bg='none' className='m-2 text-light' style={{width:'max-content',fontSize:'small',letterSpacing:'1px',backgroundColor:'#1f6a6a'}}>{x}</Badge>
                                            )) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default FeatureTable;