import { isEmpty } from 'lodash';
import React from 'react';
import { Badge, Dropdown} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Header = () => {
    //name of user
    const homeInfo = useSelector(state => state.homeInfo);
    return ( 
        <div className='header-wrapper'>
            <div className='header-config'>
                <div className='header-config-name mx-1'>
                    <Dropdown className='shadow'>
                        <Dropdown.Toggle id="dropdown-variants" className='username-dropdown' variant="none">
                            {isEmpty(homeInfo) ? "" : homeInfo[0].user_info}
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="light" className='text-end'>
                            <Link to="/dashboard/profile"><Dropdown.ItemText>Profile</Dropdown.ItemText></Link>
                            <Dropdown.Divider />
                            <Link to='/logout'><Dropdown.ItemText>LogOut</Dropdown.ItemText></Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='header-config-notif mx-1'>
                    <Badge className={isEmpty(homeInfo) ? "header-config-notif-count d-none" : "header-config-notif-count d-inline-bloch"} pill bg="danger">{isEmpty(homeInfo) ? 0 : homeInfo[0].notification.count}</Badge>
                    <Dropdown className='shadow'>
                        <Dropdown.Toggle id="dropdown-variants" variant="none" className='notification-dropdown'>
                            <img src='../assets/images/notif-vector.png' alt='notification-logo'/>
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu variant="light">
                            <Dropdown.Item><h6>Messages</h6></Dropdown.Item>
                            <Dropdown.Divider /> */}

                            {/* {isEmpty(homeInfo) ? (<Dropdown.Item>پیام تازه وجود ندارد.</Dropdown.Item>) : homeInfo[0].notification.message.map((x,index)=> (
                                <Dropdown.Item key={index} className='d-flex align-items-center'>
                                    <div>
                                        <span className='avatar'></span>
                                    </div>
                                    <div>
                                        ...{x.content.slice(0,20)}
                                        <Dropdown.ItemText>{x.tim}</Dropdown.ItemText>
                                    </div>
                                    <div>
                                        <i className='fa fa-trash fa-2x'></i>
                                    </div>
                                
                                </Dropdown.Item>
                            ))} */}
                                {/* <Dropdown.Divider />
                            <Button className='w-100 px-2'>مشاهده‌ی همه پیامها</Button>
                        </Dropdown.Menu> */}
                    </Dropdown> 
                </div>
                {/* <div className='header-config-lang mx-1'>
                    <Form.Select className='language-dropdown shadow'>
                        <option value="fa">FA</option>
                        <option value="en">EN</option>
                    </Form.Select>
                </div> */}
            </div>
            <div className='header-logo'>
    <div className='logo' >
        <img
            src={isEmpty(homeInfo) ? "" : homeInfo[0].logo}
            alt='logo'
            style={{ transform: 'scale(2)' }}
        />
    </div>
</div>
        </div>
    );
}
 
export default Header;