import config from './config.json';
import http from './httpService';

//login and get refreshToken & successToken from server
export const LoginForm = (userData) => {
    return http.post(`${config.AriisApi}/account/token/`,userData);
};

//send refresh token and get access token
export const refreshToken = (refreshToken) => {
    return http.post(`${config.AriisApi}/account/token/refresh/`,refreshToken);
};

//send refresh token to block when user logout
export const blockToken = (refreshToken) => {
    return http.post(`${config.AriisApi}/account/token/blacklist/`,refreshToken);
};

//get all elemantry data for dashboard
export const homeData = () =>{
    return http.get(`${config.AriisApi}/account/home/`);
}


//TODO: get all menu -----------------------------------
export const get_menu = () => {
    return http.get(`${config.AriisApi}/account/menu/`);
}
// add menu
export const add_menu = (data) => {
    return http.post(`${config.AriisApi}/account/menu/`,data,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
    });
}
// delete menu
export const delete_menu = (menu) => {
    return http.delete(`${config.AriisApi}/account/menu/`,{params: menu});
}

//TODO: get all model -----------------------------------
export const get_model = () => {
    return http.get(`${config.AriisApi}/account/product/`)
}
// add model
export const add_model = (data) => {
    return http.post(`${config.AriisApi}/account/product/`,data,{
        headers:{
            'Content-Type': `multipart/form-data;application/json boundary=${data._boundary}`
        }
    })
}
// delete model
export const delete_model = (data) => {
    return http.delete(`${config.AriisApi}/account/product/`,{params:data});
}
// edite model
export const edite_model = (data) => {
    return http.put(`${config.AriisApi}/account/product/`,data);
}

// check procode on the server when user uploading product
export const checkProCode = (procode) => {
    return http.post(`${config.AriisApi}/account/checkp/`,procode)
}
// TODO: get all features----------------------------------------------
export const getFeature = () => {
    return http.get(`${config.AriisApi}/account/featurs/`);
}

// TODO: get all ticket----------------------------------------------
export const getTickets = () => {
    return http.get(`${config.AriisApi}/account/ticket/`);
}
// sent ticket's reply
export const replyTicket = (data) => {
    return http.post(`${config.AriisApi}/account/ticket/`,data)
}
// sent new ticket
export const newTicket = (data) => {
    return http.post(`${config.AriisApi}/account/ticket/`,data)
}

