import React from 'react'
import { Modal,Button} from 'react-bootstrap';
import PropTypes, { array } from "prop-types";
import { isEmpty } from 'lodash';

const AddTextureModal = ({handleShowModal,handleCloseModal,listFace,handleAddPic,ConFaceList,RejFaceList,deleteFace}) => {
    return ( 
        <Modal
            show={handleShowModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div>
                    <p>Please upload the photo of the desired size</p>
                    <div className='d-flex'>
                        <div className='w-50 justify-content-center'>
                            <label htmlFor='filepic' className='btn btn-primary d-block w-75 mb-1'>Upload Image</label>
                            <input type="file" className='d-none' id='filepic' accept="image/*" multiple onChange={e => handleAddPic(e)}/>
                            <small className="blockquote-footer">
                                {/* {(sizePic === true) ? 
                                (<i className='fa fa-check' style={{color:"green"}}></i>) : 
                                ((sizePic === false) ? (<small style={{color:"red"}}>سایز بیش از حد مجاز است</small>) : 
                                ("حجم فایل باید کمتر از 20 مگابایت باشد"))} */}
                            </small>
                        </div>
                        <div className='row w-50 text-end justify-content-end position-relative'>
                            {isEmpty(listFace) ? "" : listFace.map((x,index) => (
                                <div className='col-5 col-md-4 position-relative'>
                                    <span className='position-absolute text-danger top-0 translate-middle-x mt-1' role="button" onClick={() => deleteFace(x.lastModified)}><i className='fa fa-close'></i></span>
                                    <img src={URL.createObjectURL(x)} key={index} className='p-1' alt='محل مدل' width="75" height="75"/>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={e => {
                    if(!isEmpty(listFace)){
                         handleCloseModal();
                         ConFaceList();
                    }
                }}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={e => {
                    handleCloseModal();
                    RejFaceList()
                }}>Cancel</Button>
            </Modal.Footer>
        </Modal>
     );
}
AddTextureModal.propTypes = {
    handleShowModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    handleAddPic: PropTypes.func,
    ConFaceList: PropTypes.func,
    RejFaceList: PropTypes.func,
    deleteFace: PropTypes.func,
    listFace: array,
}
 
export default AddTextureModal;