import React, { Fragment,useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { context } from '../../context/context';
const Login = () => {
    const [cursorX,setCursorX] = useState();
    const [cursorY,setCursorY] = useState();
    const login_context = useContext(context);
    const {
        username,
        setUserName,
        password,
        setPassword,
        forgetPass,
        setForgetPass,
        showPassword,
        validator,
        handleLoginForm,
        handleForgetPassForm,
        handleShowPass
    } = login_context;
    
    useEffect(() => {
        localStorage.removeItem('Refresh_Token');
        localStorage.removeItem('Access_Token');
    },[])



    window.addEventListener('mousemove', (e) => {
        setCursorX(e.pageX);
        setCursorY(e.pageY);
    })



    // const handleChangeField = () => {
    //     let login = document.querySelector('.login-form-content');
    //     let forgetPass = document.querySelector('.forget-pass-form');
    //     login.classList.add("login-form-content-active")
    //     forgetPass.classList.add("forget-pass-form-active")
    // }
    // const handleUnChangeField = () => {
    //     let login = document.querySelector('.login-form-content');
    //     let forgetPass = document.querySelector('.forget-pass-form');
    //     login.classList.remove("login-form-content-active")
    //     forgetPass.classList.remove("forget-pass-form-active")
    // }

    return (
        <Fragment>
            <Helmet>
                <title>LogIn</title>
            </Helmet>
                <div className='login w-100 position-relative'>
                    <div className='login-field w-100'>
                        <div className='login-content w-100'>
                            <div className='login-sideright w-50'>
                                <div className='login-header'>
                                    <div className='login-header-img text-center py-3' style={{fontFamily:'serif'}}>
                                        {/* <img src='../assets/images/logo-ariis.png' alt='logo-Ariis'/> */}
                                        <h1>LOGIN</h1>
                                    </div>
                                    <div className="one-row_bottom_divider" style={{height:"55px"}}>
                                        <svg width="100%" fill="#ffffff" height="70" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 150">
                                            <path d="M 0 26.1978 C 275.76 83.8152 430.707 65.0509 716.279 25.6386 C 930.422 -3.86123 1210.32 -3.98357 1439 9.18045 C 2072.34 45.9691 2201.93 62.4429 2560 26.198 V 172.199 L 0 172.199 V 26.1978 Z">
                                            <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="10s" values="M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z; M0 86.3149C316 86.315 444 159.155 884 51.1554C1324 -56.8446 1320.29 34.1214 1538 70.4063C1814 116.407 2156 188.408 2560 86.315V232.317L0 232.316V86.3149Z; M0 53.6584C158 11.0001 213 0 363 0C513 0 855.555 115.001 1154 115.001C1440 115.001 1626 -38.0004 2560 53.6585V199.66L0 199.66V53.6584Z; M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z">
                                            </animate>
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className='login-form-content  m-auto'>
                                    <form action='' id='login-frm' onSubmit={(e) => handleLoginForm(e)}>
                                        <div className='login-form w-75'>
                                            <div className='form-input'>
                                                <label>Username:</label>
                                                <sub>*</sub>
                                                <input type='text' name='username' autoComplete="username" placeholder='Username...' value={username} onChange={e => {
                                                    setUserName(e.target.value.trim());
                                                    validator.current.showMessageFor("username");
                                                }}/>
                                                {validator.current.message("username",username,"required")}
                                            </div>
                                            <div className='form-input'>
                                                <label>Password:</label>
                                                <sub>*</sub>
                                                <input type={showPassword ? "password" : "text"} name='password' autoComplete="current-password" placeholder='Password...' value={password} onChange={e => {
                                                    setPassword(e.target.value.trim());
                                                    validator.current.showMessageFor("password")
                                                }}/>
                                                <span className={showPassword ? "fa fa-eye-slash" : " fa fa-eye"} id="togglePassword" onClick={() => handleShowPass()}></span>
                                                {validator.current.message("password",password,"required|min:5")}
                                            </div>
                                            <div className='form-input'>
                                                <Button type='submit' className='w-100'>Login</Button>
                                            </div>
                                            <Form.Check type="checkbox" label="Remember Me" />
                                            <div className='form-input text-center my-3 '>
                                                <span role="button">Forgot Password?</span>
                                            </div>
                                        </div>
                                    </form>
                                    <div className='login-footer mt-'>
                                        <div className='my-4'>
                                        <Link to='/register'><b>Sign In</b></Link>
                                        </div>
                                        <div className='separator'>
                                            <hr/><span>Or</span><hr/>
                                        </div>
                                        <div className='login-footer-social mt-3'>
                                            <p>Follow Us</p>
                                            <ul>
                                                <li><a href='#'><i className='fa fa-facebook'></i></a></li>
                                                <li><a href='#'><i className='fa fa-twitter'></i></a></li>
                                                <li><a href='#'><i className='fa fa-telegram'></i></a></li>
                                                <li><a href='#'><i className='fa fa-whatsapp'></i></a></li>
                                                <li><a href='#'><i className='fa fa-linkedin '></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------------------------------------------------------------------- */}
                                {/* <div className='forget-pass-form text-center'>
                                    <Form action='' id='forget-pass-frm' method='post'>
                                        <div className='login-form w-75'>
                                            <div className='form-input'>
                                                <label htmlFor='user-forgetpass'>ایمیل یا شماره تلفنی که با آن ثبت نام کرده‌اید را وارد کنید:</label>
                                                <input type='text' name='user-forgetpass' autoComplete="user-forgetpass" placeholder='ایمیل یا شماره تلفن...' value={forgetPass} onChange={e => {
                                                    setForgetPass(e.target.value);
                                                    validator.current.showMessageFor("user-forgetpass");
                                                }}/>
                                                {validator.current.message("user-forgetpass",forgetPass,"required")}
                                            </div>

                                            <div className='form-input'>
                                                <Button type='submit' className='w-100'>ارسال ایمیل</Button>
                                            </div>
                                        </div>
                                    </Form>
                                    <div className='login-footer mt-'>
                                        <div className='my-4'>
                                            <span role="button" onClick={handleUnChangeField}><b>بازگشت</b> <i className='fa fa-arrow-left'></i></span>
                                        </div>
                                        <div className='separator'>
                                            <hr/><span>or</span><hr/>
                                        </div>
                                        <div className='login-footer-social mt-3'>
                                            <p>ما را در شبکه‌های اجتماعی دنبال کنید</p>
                                            <ul>
                                                <li><a href='#'><i className='fa fa-facebook'></i></a></li>
                                                <li><a href='#'><i className='fa fa-twitter'></i></a></li>
                                                <li><a href='#'><i className='fa fa-telegram'></i></a></li>
                                                <li><a href='#'><i className='fa fa-whatsapp'></i></a></li>
                                                <li><a href='#'><i className='fa fa-linkedin '></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='login-sideleft w-50'></div>
                        </div>
                    </div>
                </div>
            <div className='cursor-outline' style={{left:cursorX + 'px',top:cursorY + 'px'}}></div>
            <div className='cursor' id='coursor' style={{left:cursorX + 'px',top:cursorY + 'px'}}></div>
        </Fragment> 
     );
}
 
export default Login;