import React, { useRef, useState } from 'react';
import { context } from './context';
import SimpleReactValidator from 'simple-react-validator';
import { LoginForm } from '../services/transferData';
import { ErrorMessage, SuccessMessage } from '../utils/message';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from 'axios';


const UserContext = ({children}) => {
    // TODO:--------------All state--------------
        // login
    const [username,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [forgetPass,setForgetPass] = useState("");
    const [showPassword,setShowPassword] = useState(true);
    const [,setForceUpdate] = useState();
        // register
    const [page,setPage] = useState(0);
    const [paginate,setPaginate] = useState(0);
    const [formData,setFormData] = useState({
        compayName:"",
        userName:"",    
        password:"",
        confrimPassword:"",
        email:"",
        tel:"",
        country:"",
        city:"",
        address:"",
        companyDetail:"",
        package:"",
        package_price:""
    })
    // TODO:--------------All hooks--------------
    const redirect = useNavigate();
    const dispatch = useDispatch();


    // TODO: validate all input------------------
    const validator = useRef(new SimpleReactValidator({
        messages:{
            required:"Please fill out this field!",
            min:"The desired field must be at least 5 characters long!",
        },
        element: (message) => <div style={{color:'red',fontSize:'small',paddingTop:'5px',textAlign:'left'}}>{message}</div>
    }));

    // TODO: reset all input value-------------------
    const resetStates = () => {
        setUserName("");
        setPassword("");
    }

    // TODO: show password function------------------
    const handleShowPass = () => {
        setShowPassword(!showPassword);
    }
    // TODO: login form function-------------------
    const handleLoginForm = async (e) => {
        e.preventDefault();
        const user = Object.freeze({
            username,
            password
        });
        try {
            if (validator.current.allValid()) {
                const {status,data} = await LoginForm(user);
                if(status === 200) {
                    SuccessMessage("Login was successful.");
                    localStorage.setItem("Refresh_Token",data.refresh);
                    localStorage.setItem("Access_Token",data.access);
                    axios.defaults.headers.common["Authorization"] = "JWT " + data.access;
                    resetStates();
                    redirect("/dashboard");
                }else if(status === 406){
                    ErrorMessage("password or username is not true!")
                }
            }else{
                validator.current.showMessages();
                setForceUpdate(1);
            }
        } catch (error) {
            if (error.response.status === 406) {
                ErrorMessage("username or password is incorrect!")
            }else{
                ErrorMessage("Login was not successful,Tty Again!");
            }
        }
    }
    //TODO: forgetPass form function-------------------
    // const handleSubmitForgetPassForm = async (e) => {
    //     e.preventDefault();
    //     const userData = {
    //         userForgetPass
    //     }
        
    //     try {
    //         const {data,status} = await loginForm(userData);
    //         if(status === 201) {
    //             toast("ورود موفقیت آمیز بود.",{
    //                 position:'bottom-right',
    //                 autoClose:1500,
    //                 closeOnClick:true,
    //                 closeButton:true
    //             })
    //             console.log(data);
    //         }
    //     } catch (error) {
    //         toast.error("ورود موفقیت آمیز نبود!",{
    //             position:"bottom-right",
    //             autoClose:1500,
    //             closeButton:true,
    //             closeOnClick:true
    //         });
    //     }
    // }
    return ( 
        <context.Provider value={{
            // login
            username,
            setUserName,
            password,
            setPassword,
            forgetPass,
            showPassword,
            validator,
            setForgetPass,
            handleLoginForm,
            handleShowPass,
            // register
            page,
            setPage,
            paginate,
            setPaginate,
            formData,
            setFormData
        }}>
            {children}
        </context.Provider>
     );
}
 
export default UserContext;