import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { get_model } from "../services/transferData";

export const fetchModels = createAsyncThunk("models/fetchModels",async () => {
    const {data} = await get_model();
    return data;
})


const initialState= [];

const modelSlice = createSlice({
    name:"models",
    initialState,
    reducers:{
        addModel:(state,action) => {
            return action.payload;
        },
        editeModel:(state,action) => {
            return action.payload;
        },
        deleteModel:(state,action) =>{
            return action.payload
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchModels.fulfilled,(state,action) => {
            return action.payload;
        })
    }
})
export const {addModel,editeModel,deleteModel} = modelSlice.actions;
export default modelSlice.reducer;