import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Button, Form } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { newTicket } from '../../../services/transferData';
import { ErrorMessage, SuccessMessage } from '../../../utils/message';
import { useDispatch } from 'react-redux';
import { addTicket } from '../../../reducers/tickets';
import { useNavigate } from 'react-router';

const AddTicket = () => {
    // TODO: state
    const [ticket,setTicket] = useState({
        titleticket:'',
        contentticket:''
    })
    const [,setForceUpdate] = useState();
    const dispatch = useDispatch();
    const redirect = useNavigate();

    // TODO: validate all input------------------
    const validator = useRef(new SimpleReactValidator({
        messages:{
            required:"Please fill out this field!",
            min:"The desired field must be at least 5 characters long",
        },
        element: (message) => <div style={{color:'red',fontSize:'small',paddingTop:'5px',textAlign:'left'}}>{message}</div>
    }));

    // TODO: set users caracters at input to the state width name's input
    const handleChangeState = (e) => {
        const {name,value} = e.target;
        setTicket({...ticket,[name] : value});
    }

    // TODO: submit ticket form to the server
    const handleSubmitTicketForm = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title',ticket.titleticket);
        formData.append('content',ticket.contentticket);
        try {
            if(validator.current.allValid()){
                const {data,status} = await newTicket(Object.freeze(formData));
                if (status === 200) {
                    SuccessMessage('Message sent successfully!');
                    handleResetState();
                    dispatch(addTicket(data));
                    redirect('/dashboard/ticket');
                }
            }else{
                validator.current.showMessages();
                setForceUpdate(1);
            }
        } catch (error) {
            ErrorMessage('Unfortunately, the message was not sent!');
        }

    }

    // TODO: reset state
    const handleResetState = () => {
        setTicket({
            titleticket:'',
            contentticket:''
        })
    }
    console.log(ticket);
    return ( 
        <>
            <Helmet>
                <title>Dashboard | Add Ticket</title>
            </Helmet>
            <div className='add-ticket-wrapper'>
                <div className='add-ticket-container'>
                    <div className='dashboard-title'>
                        <h6 className='d-inline-flex'>Tickets</h6>
                        <div className='title-devider'></div>
                    </div>
                    <div className='add-ticket-body p-3'>
                        <Form>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-input'>
                                        <label htmlFor='ticket-title'>Message Subject:</label>
                                        <sub>*</sub>
                                        <input type='text' placeholder='subject...' id='ticket-title' value={ticket.titleticket} name='titleticket' onChange={e => {
                                            handleChangeState(e);
                                            validator.current.showMessageFor('titleticket');
                                        }}/>
                                        {validator.current.message("titleticket",ticket.titleticket,"required|min:3")}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-input'>
                                        <label htmlFor='ticket-message'>Message:</label>
                                        <sub>*</sub>
                                        <textarea placeholder='message...' rows='8' id='ticket-message' style={{resize:'none'}} value={ticket.contentticket} name='contentticket' onChange={e => {
                                            handleChangeState(e);
                                            validator.current.showMessageFor('contentticket');
                                        }}/>
                                        {validator.current.message("contentticket",ticket.contentticket,"required|min:3")}
                                    </div>
                                </div>
                            </div>
                            <div className='devider'></div>

                            <div className='row form-input justify-content-end'>
                                <Button type='submit' className='p-2 w-25' onClick={handleSubmitTicketForm}>Send</Button>
                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </>
     );
}
 
export default AddTicket;