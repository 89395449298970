import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { context } from '../../context/context';
import Factor from './Factor';
import Info from './Info';
import Package from './Package';

const Register = () => {
    const register_context = useContext(context);
    const {
        page,
        setPage,
        paginate,
        setPaginate,
        formData,
        setFormData
    } = register_context;

    useEffect(() => {
        var obj =[
            document.getElementById('account'),
            document.getElementById('service'),
            document.getElementById('factor'),
            document.getElementById('payment'),
        ]
        setPaginate(obj);
    },[])

    let fieldSet_info = null;
    let fieldSet_factor = null;
    if (page === 0) {
        fieldSet_info =  <Info formData={formData} setFormData={setFormData}/>;
    }else if(page === 1){
        fieldSet_info = <Package formData={formData} setFormData={setFormData}/>;
    }else if(page === 2){
        fieldSet_factor = <Factor/>;
    }else{
        fieldSet_factor = null
    }
    return (
        <Fragment>
            <Helmet>
                <title>آریس | ثبت نام</title>
            </Helmet>
            <div className='container-fluid'>   
                <div className='row justify-content-center'>
                    <div className='col-md-11'>
                        <Card className='text-center'>
                            <Card.Header>
                                <h2>تکمیل اطلاعات حساب کاربری</h2>
                                <ul className='perogresbar'>
                                    <li id='account' className='active'><strong>اطلاعات کاربری</strong></li>
                                    <li id='service'><strong>انتخاب سرویس</strong></li>
                                    <li id='factor'><strong>پیش فاکتور</strong></li>
                                    <li id='payment'><strong> پرداخت</strong></li>
                                </ul>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                {fieldSet_info}
                                </Form>
                                <Form>
                                {fieldSet_factor}
                                </Form>

                            </Card.Body>
                            <Card.Footer>
                                <Button className='next' onClick={() => {
                                    paginate[page + 1].classList.add("active")
                                    setPage(page + 1);
                                }} disabled={page === 3}>بعدی</Button>
                                <Button className='previous' onClick={() => {
                                    paginate[page].classList.remove("active")
                                    setPage(page - 1);
                                }} disabled={page === 0}>بازگشت</Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment> 
    );
}
 
export default Register;