import Compress from '../compress';


export const compressImage = (element) => {
    const compress = new Compress();
    // const files = [...evt.target.files];
    const files = [element];
    console.log(files);
    return new Promise((resolve,reject) => {
        compress.compress(files, {
            size: 4, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
        }).then((results) => {
            const output = results[0];
            resolve(output.prefix+output.data);
        })
    })
    
}