import React, { Fragment, useEffect, useState } from 'react'
import { Modal,Button, Form, FormSelect, ListGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

import PropTypes from "prop-types";
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty } from 'lodash';
import AddTextureModal from './AddTextureModal';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../utils/message';
import { useDispatch, useSelector } from 'react-redux';
import { edite_model } from '../../services/transferData';
import { compressImage } from '../../utils/compress';
import { editeModel } from '../../reducers/models';

const EditModal = ({showEditeModal,closeEditeModal,currentModel}) => {
    const [name,setName] = useState("");
    const [procode,setProcode] = useState("");
    const [situation,setSituation] = useState();
    const [menu,setMenu] = useState();
    const [feature,setFeature] = useState();
    const [key,setKey] = useState("");
    const [value,setValue] = useState("");
    const [listFace,setListFace] = useState([]);
    const [showModal,setShowModal] = useState(false);

    const filter = useSelector(state => state.features);
    const menus = useSelector(state => state.menus);
    const dispatch = useDispatch();
    useEffect(() => {
        setName(currentModel.name);
        setProcode(currentModel.procode);
        setSituation(currentModel.act);
        setMenu(menus.filter(x => x.object_id === currentModel.model_parent));
        setFeature(currentModel.featur_value)


        return () => {
            setName();
            setProcode();
            setSituation();
            setMenu();
            setFeature();
        }
    },[currentModel])

    // TODO:
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        name === "name" ? setName(value) : setSituation(value)
    };

    // TODO: delete default feature
    const handleDeleteFeature = (x,y) => {
        let element = document.getElementsByClassName("form-check-input");
        let newFeature = JSON.parse(JSON.stringify(feature));
        if(x[0] === "size" || x[0] === "سایز"){
            delete newFeature[x[0]][y];
            if (Object.values(newFeature[x[0]]).length === 0) delete newFeature[x[0]];
        }else{
            const index = newFeature[x[0]].indexOf(y);
            if(index !== -1){
                newFeature[x[0]].splice(index,1);
                if (Object.values(newFeature[x[0]]).length === 0) delete newFeature[x[0]];
            }
        }
        for (let i = 0; i < element.length; i++) {
            if(element[i].value === y) element[i].checked = false;
        }
        setFeature(newFeature);
    }

    // TODO: add data to feature state wiht check
    const handleCheck = (event,key) => {
        const {value} = event.target;
        const newFeature = JSON.parse(JSON.stringify(feature));
        if(key === "سایز" || key === "size"){
            setKey(key);
            setValue(value);
            handleShowModal();
                if(isEmpty(newFeature) || newFeature[key] === undefined){
                    newFeature[key] = {};
                    newFeature[key][value] = [];
                }else{
                    newFeature[key][value] = [];
                }
        }else{
            if(isEmpty(newFeature) || newFeature[key] === undefined){
                newFeature[key] = new Array(value);
            }else if(newFeature[key].indexOf(value) !== -1 ){
                WarningMessage("This feature already exists!");
            }else{
                newFeature[key].push(value);
            }
            
        }
        setFeature(newFeature);
    }

    // TODO:remove data from feature state with uncheck
    const handleUnCheck = (event,key) => {
        const {value} = event.target;
        const newFeature = JSON.parse(JSON.stringify(feature));
        if(key === "سایز" || key === "size"){
                delete newFeature[key][value];
                if (Object.values(newFeature[key]).length === 0) delete newFeature[key];
        }else{
            const index = newFeature[key].indexOf(value);
            if (index !== -1) {
                newFeature[key].splice(index,1);
                if (Object.values(newFeature[key]).length === 0) delete newFeature[key];
            }
        }
        setFeature(newFeature);
    }
    // TODO:
    const handleAddPic = event => {
        const files = [...event.target.files];
        if (files && files.length <= 10) {
            setListFace(files);
        }else{
            WarningMessage("The number of photos must be less than 10!")
        }
    }
    
    
    
    // TODO: confrim or reject modal add face texture
    const handleConfaceList = () => {
        const newFeature = JSON.parse(JSON.stringify(feature))
        const arrayBase64 = [];
        listFace.forEach(ele => {
            compressImage(ele).then((res) => {
                arrayBase64.push(res);
            });
        })
        newFeature[key][value] = arrayBase64;
        setFeature(newFeature);
        setListFace([]);

    }
    const handleRejFaceList = () => {
        const newFeature = JSON.parse(JSON.stringify(feature))
        setListFace([]);
        delete newFeature[key][value];
        if (Object.values(newFeature[key]).length === 0) delete newFeature[key];
        setFeature(newFeature);
        let x = document.getElementsByClassName("form-check-input");
        for (let i = 0; i < x.length; i++) {
            if(x[i].value === value) x[i].checked = false;
        }
    }

    // TODO: delete every img in faceList per click and set new list in faceList
    const handleDeletSingleFace = (id) => {
        const filterFaceList =listFace.filter(x => x.lastModified !== id);
        setListFace(filterFaceList);
    }

    // TODO:
    const handleSubmitEditModel = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name',name);
        formData.append('procode',procode);
        formData.append('act',situation);
        formData.append('menu',menu[0] ? menu[0].object_id : "");
        formData.append("featur_value",JSON.stringify(feature));
        try {
            const {data,status} = await edite_model(formData);
            if(status === 200){
                dispatch(editeModel(data));
                SuccessMessage('Texture edited successfully!');
                closeEditeModal();
            }
        } catch (error) {
            ErrorMessage("The texture was not added successfully!");
        }
    }


    // TODO: show and close modal
    const handleShowModal = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    }
    
    return ( 
        <Fragment>
            <Modal
                show={showEditeModal}
                onHide={closeEditeModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='add-model-wrapper'>
                        <div className='add-model-container w-100'>
                            <div className='add-model-body'>
                                <Form>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='form-input'>
                                                <label htmlFor='name'>Product Name:</label>
                                                <sub>*</sub>
                                                <Form.Control type='text' placeholder='product name...' value={name} id='name' name='name' onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-input'>
                                                <label htmlFor='procode'>Product Code:</label>
                                                <sub>*</sub>
                                                <Form.Control type='text' placeholder='product code...' readOnly value={procode} id='procode' name='procode'/>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-input'>
                                                {/* <sub>*</sub> */}
                                                <label htmlFor='prositu'>Product Status:</label>
                                                <FormSelect id='prositu' name='prositu' value={situation} onChange={handleInputChange}>
                                                    <option value="active">Active</option>
                                                    <option value="deactive">Deactive</option>
                                                </FormSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-input'>
                                                <sub></sub>
                                                <label htmlFor='menu'>Category:</label>
                                                <Multiselect
                                                    options={menus}
                                                    showCheckbox
                                                    onSelect={(e) => setMenu(e)}
                                                    onRemove={e => setMenu("")}
                                                    placeholder="Category..."
                                                    customCloseIcon={<i className='fa fa-close'></i>}
                                                    customArrow={<i className="fa fa-angle-down"></i>}
                                                    hidePlaceholder
                                                    showArrow
                                                    avoidHighlightFirstOption
                                                    selectionLimit= {1}
                                                    displayValue="name"
                                                    selectedValues={menu}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='model-feild'>
                                            {!isEmpty(feature) ? Object.entries(feature).map((x,index) => x[0] === "size" || x[0] === "سایز" ? (
                                                <div key={index}>
                                                    <h6 className='model-feild-title'>{x[0]}</h6>
                                                    <div className='model-feild-content'>
                                                        {Object.keys(x[1]).map((y,index) => ( 
                                                            <ListGroup className="mb-3" key={index}>
                                                                <ListGroup.Item>{y}<span className='btn-close float-end' onClick={e => handleDeleteFeature(x,y)}></span></ListGroup.Item>
                                                            </ListGroup>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div key={index}>
                                                    <h6 className='model-feild-title'>{x[0]}</h6>
                                                    {!isEmpty(x[1]) ? x[1].map((y,index) => (
                                                        <div className='model-feild-content' key={index}>
                                                            <ListGroup className="mb-2">
                                                                <ListGroup.Item>{y}<span className='btn-close float-end' onClick={e => handleDeleteFeature(x,y)}></span></ListGroup.Item>
                                                            </ListGroup>
                                                        </div>
                                                    )) : ""}
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <Modal.Header>
                                            <Modal.Title>Add Feature</Modal.Title>
                                        </Modal.Header>
                                        <div className='model-feild'>
                                            {!isEmpty(filter) ? Object.entries(filter).map((x,index) => (
                                                <div key={index}>
                                                    <h6 className='model-feild-title'>{x[0]}</h6>
                                                    <div className='model-feild-content'>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                            {!isEmpty(x[1]) ? x[1].map((y,index) => (
                                                                <Form.Check key={index} type="checkbox" label={y}  value={y} onChange={e => e.currentTarget.checked ? handleCheck(e,x[0]) : handleUnCheck(e,x[0])}/>
                                                            )) : ""}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )):""}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmitEditModel}>Save Changes</Button>
                    <Button variant="secondary" onClick={closeEditeModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <AddTextureModal handleShowModal={showModal} handleCloseModal={handleCloseModal} listFace={listFace} handleAddPic={handleAddPic} ConFaceList={handleConfaceList} RejFaceList={handleRejFaceList} deleteFace={handleDeletSingleFace}/>
        </Fragment>
    );
}
EditModal.propTypes = {
    showEditeModal: PropTypes.bool,
    closeEditeModal: PropTypes.func,
}
 
export default EditModal;