import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { getFeature } from '../services/transferData';

export const fetchFeature = createAsyncThunk("features/fetchFeature",async () => {
    const {data} = await getFeature();
    return data.en;
})

const initialState = {};
const featureSlice = createSlice({
    name:"features",
    initialState,
    extraReducers:(builde) => {
        builde.addCase(fetchFeature.fulfilled,(state,action) => {
            return action.payload;
        })
    }
})

export default featureSlice.reducer;