import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { fetchModels } from '../../../reducers/models';
import ChartBar from '../../charts/ChartBar';
import { useSelector } from 'react-redux';
import { fetchHome } from '../../../reducers/homeInfo';
import { isEmpty } from 'lodash';
import { fetchMenu } from '../../../reducers/menu';
import { fetchFeature } from '../../../reducers/features';
import { fetchTickets } from '../../../reducers/tickets';

const Dashboard = () => {
    const homeInfo = useSelector(state => state.homeInfo);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchHome());
        dispatch(fetchMenu());
        dispatch(fetchModels());
        dispatch(fetchFeature());
        dispatch(fetchTickets());
    },[]);

    return ( 
        <Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div className='dashboard-wrapper'>
                <div className='dashboard-container'>
                    <div className='dashboard-signboard'>
                        <div className='signboard-card shadow'>
                            <div className='dashboard-title'>
                                <h6>Subscription:</h6>
                                <p className='title-devider'></p>
                            </div>
                            <div className='signboard-body signboard-box1'>
                                <p>Days Remaining:</p>
                                <p>{isEmpty(homeInfo) ? "" : homeInfo[0].remain_day}</p>
                            </div>
                        </div>
                        <div className='signboard-card shadow'>
                            <div className='dashboard-title'>
                                <h6>Logins:</h6>
                                <p className='title-devider'></p>
                            </div>
                            <div className='signboard-body signboard-box2'>
                                <p>Last Login:</p>
                                <p>{isEmpty(homeInfo) ? "" : homeInfo[0].last_login}</p>
                            </div>
                        </div>
                        <div className='signboard-card shadow'>
                            <div className='dashboard-title'>
                                <h6>Products:</h6>
                                <p className='title-devider'></p>
                            </div>
                            <div className='signboard-body signboard-box3'>
                                <p>Number Of Textute:</p>
                                <p>{ isEmpty(homeInfo) ? "" : homeInfo[0].number_of_total_model}</p>
                            </div>
                        </div>
                        <div className='signboard-card shadow'>
                            <div className='dashboard-title'>
                                <h6>Messages</h6>
                                <p className='title-devider'></p>
                            </div>
                            <div className='signboard-body signboard-box4'>
                                <p>Unread:</p>
                                <p> {isEmpty(homeInfo) ? "" : homeInfo[0].notification.count}</p>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-chart'>
                        <div className='dashboard-title'>
                            <h6>Reports</h6>
                            <p className='title-devider'></p>
                        </div>
                        <div className='chart-container mt-3'>
                            <ChartBar/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default Dashboard;