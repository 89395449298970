import { createContext } from "react";

export const context = createContext({
    // login
    userName:"",
    setUserName: () => {},
    password:"",
    setPassword: () => {},
    forgetPass:"",
    setForgetPass: () => {},
    showPassword: true,
    validator: null,
    handleLoginForm: () => {},
    handleForgetPassForm: () => {},
    handleShowPass: () => {},
    // register
    page:0,
    setPage: () => {},
    paginate:0,
    setPaginate: () => {},
    formData: {},
    setFormData: () => {}
})